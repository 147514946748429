import router from "../../router";
var i18n = require("../../i18n").default;
export default [
  // {
  //   title: "الرئيسية",
  //   icon: "fi fi-rr-home",
  //   to: "/dashboard",
  //   role: router.find(x => x.path == '/dashboard').meta.auth,
  // },
  {
    title: i18n.t("profile"),
    icon: "fi fi-rr-user",
    to: "/profile",
    role: [
      "Admin",
      "Employee",
      "Benefit",
      "Security",
      "Outcoming",
      "Incoming",
      "Manager",
    ],
  },
  {
    title: i18n.t("idarh-almstkhdmyn"),
    icon: "fi fi-rr-users",
    // to: '/users',
    role: ["Admin", "Manager"],
    childrens: [
      {
        title: i18n.t("almjmwaat"),
        to: "/users/groups",
        icon: "fi fi-rr-home",
      },
      {
        title: i18n.t("almstkhdmyn"),
        to: "/users",
        icon: "fi fi-rr-home",
      },
    ],
  },
  //users end
  {
    title: i18n.t("almnzmat"),
    icon: "fi fi-rr-chart-tree",
    acive: false,
    role: ["Admin", "Manager"],
    childrens: [
      {
        title: i18n.t("almnzmat"),
        to: "/organizations",
        icon: "fi fi-rr-home",
      },
      {
        title: i18n.t("anwaa-almnzmat"),
        to: "/organizationsTypes",
        icon: "fi fi-rr-home",
      },
    ],
  },
  {
    title: i18n.t("anwaa-alhqwl"),
    icon: "fi fi-rr-browser",
    to: "/fields",
    role: ["Admin"],
  },
  {
    title: i18n.t("almkhttat"),
    icon: "fi fi-rr-vector",
    to: "/workflow",
    role: ["Admin"],
  },
  {
    title: i18n.t("tqryr-almaamlat"),
    icon: "fi fi-rr-form",
    to: "/ordersReport",
    role: [
      "Employee",
      "Benefit",
      "Security",
      "Outcoming",
      "Incoming",
      "Manager",
    ],
  },
  {
    title: i18n.t("tqryr-almdkhlat"),
    icon: "fi fi-rr-browser",
    to: "/reports",
    role: [
      "Employee",
      "Benefit",
      "Security",
      "Outcoming",
      "Incoming",
      "Manager",
    ],
  },
  {
    title: i18n.t("almaamlat"),
    icon: "fi fi-rr-database",
    to: "/orders",
    role: [
      "Employee",
      "Benefit",
      "Security",
      "Outcoming",
      "Incoming",
      "Manager",
    ],
  },
  {
    title: i18n.t("maamlat-aldhyf"),
    icon: "fi fi-rr-database",
    to: "/guestSubmission",
    role: [
      "Employee",
      "Benefit",
      "Security",
      "Outcoming",
      "Incoming",
      "Manager",
    ],
  },
  {
    title: i18n.t("altsaryh-alamnyh"),
    icon: "fi fi-rr-lock",
    to: "/permit",
    role: ["Benefit", "Security"],
  },
  {
    title: i18n.t("tqryr-altsaryh"),
    icon: "fi fi-rr-settings-sliders",
    to: "/permitReports",
    role: ["Security"],
  },
  {
    title: i18n.t("qaemh-almhzwryn"),
    icon: "fi fi-rr-cross-circle",
    to: "/blackList",
    role: ["Security"],
  },
  {
    title: i18n.t("almraslat"),
    icon: "fi fi-rr-envelope",
    to: "/emails",
    role: [
      "Admin",
      "Employee",
      "Benefit",
      "Security",
      "Outcoming",
      "Incoming",
      "Manager",
    ],
  },
  {
    title: i18n.t("alsadr-walward"),
    icon: "fi fi-rr-envelope",
    childrens: [
      {
        title: i18n.t("albryd-alsadr"),
        to: "/documentsList/outgoing",
        icon: "fi fi-rr-inbox-out",
        role: ["Admin", "Employee", "Outcoming", "Incoming", "Manager"],
      },
      {
        title: i18n.t("albryd-alward"),
        to: "/documentsList/incoming",
        icon: "fi fi-rr-inbox-in",
      },
      {
        title: i18n.t("mthkrat-dakhlyh"),
        to: "/documentsList/internal",
        icon: "fi fi-rr-inbox",
        role: ["Admin", "Employee", "Outcoming", "Incoming", "Manager"],
      },
      {
        title: i18n.t("alktb-almhalh"),
        to: "/documentsList/shared",
        icon: "fi fi-rr-share",
        role: ["Admin", "Employee", "Outcoming", "Incoming", "Manager"],
      },
      {
        title: i18n.t("aljhat"),
        to: "/documents/ministries",
        icon: "fi fi-rr-list",
        role: ["Admin", "Employee", "Outcoming", "Incoming", "Manager"],
      },
      {
        title: i18n.t("categories"),
        to: "/documents/categories",
        icon: "fi fi-rr-template",
        role: ["Admin", "Employee", "Outcoming", "Incoming", "Manager"],
      },
      // {
      //   title: "اعدادات الطباعة",
      //   to: "/documents/settings",
      // },
    ],
    role: ["Admin"],
  },
  {
    title: i18n.t("albryd-alsadr"),
    to: "/documentsList/outgoing",
    icon: "fi fi-rr-inbox-out",
    role: ["Outcoming", "Incoming"],
  },
  {
    title: i18n.t("albryd-alward"),
    to: "/documentsList/incoming",
    icon: "fi fi-rr-inbox-in",
    role: ["Incoming", "Outcoming", "Manager"],
  },
  {
    title: i18n.t("mthkrat-dakhlyh"),
    to: "/documentsList/internal",
    icon: "fi fi-rr-inbox",
    role: ["Outcoming", "Incoming", "Employee", "Manager"],
  },
  {
    title: i18n.t("alktb-almhalh"),
    to: "/documentsList/shared",
    icon: "fi fi-rr-share",
    role: ["Outcoming", "Incoming", "Employee", "Manager"],
  },
  {
    title: i18n.t("aljhat"),
    to: "/documents/ministries",
    icon: "fi fi-rr-list",
    role: ["Outcoming", "Incoming"],
  },
  {
    title: i18n.t("categories"),
    to: "/documents/categories",
    icon: "fi fi-rr-template",
    role: ["Outcoming", "Incoming"],
  },
];
